@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
* {
    margin: 0px;
    padding: 0px;
    font-family: "Roboto", sans-serif;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
}

html,
body {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-x: hidden;
}

.container-xs {
    width: 100%;
    height: auto;
    max-width: 1600px;
    margin: 0px auto;
}

.web {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #fff;
    z-index: 99;
    /* position: relative; */
    position: fixed;
    top: 0px;
    left: 0px;
}
.web .web__content {
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.web .web__content .header {
    width: 100%;
    height: auto;
    padding: 50px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
}
.web .web__content .web__box {
    width: 100%;
    height: auto;
    max-width: 840px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding: 56px;
    position: relative;
    overflow: hidden;
    padding-bottom: 120px;
    display: none;
}
.web .web__content .web__box.active {
    display: block;
}
.web .web__content .web__from {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}
.web .web__content .web__from .web__box {
    display: block;
}

.web .web__content .web__from.active {
    display: flex;
}
.web .web__content .web__box.web__box--sp {
    padding-bottom: 77px;
}
.web .web__content .web__box .web__box-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}
.web .web__content .web__box .web__box-content h4 {
    font-size: 32px;
    font-weight: 700;
    color: #ed8154;
}
.web .web__content .web__box .web__box-content p {
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.16px;
    color: #417ac7;
    font-weight: 400;
    max-width: 635px;
    padding-top: 49px;
}
.web .web__content .web__box .web__box-content .custom__select {
    width: 400px;
    max-width: 100%;
    height: 60px;
    background-color: #fff;
    border: 1px solid #417ac7;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    margin-top: 40px;
}
.web .web__content .web__box .web__box-content .custom__select select {
    width: 100%;
    height: 60px;
    border: 0px;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    font-size: 24px;
    font-weight: 600;
    color: #4f4f4f;
}
.web .web__content .web__box .web__box-content .custom__select::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background-image: url("./assets/img/arrow-down.svg");
    width: 20px;
    height: 20px;
    display: block;
}
.web .web__content .web__box .web__box-content .custom__input {
    width: 100%;
    height: 60px;
    margin-top: 30px;
}
.web .web__content .web__box .web__box-content .custom__input input {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border: 1px solid #417ac7;
    border-radius: 8px;
    padding-left: 30px;
    font-size: 18px;
    color: #e0e0e0;
    outline: none;
}
.web .web__content .web__box .web__box-content .web__box-divider {
    width: 100%;
    height: 80px;
}
.web .web__content .web__box .web__box-content h5 {
    font-size: 18px;
    font-weight: 700;
    color: #828282;
    width: 100%;
    text-align: left;
}
.web .web__content .web__box .web__box-content h5.rotate {
    margin-top: 20px;
}
.web .web__content .web__box .web__box-content .date__list {
    width: 100%;
    height: auto;
    padding-top: 40px;
}
.web .web__content .web__box .web__box-content .date__list .date__placeholder {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__placeholder
    img {
    width: 16px;
    height: 18px;
    object-fit: contain;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__placeholder
    span {
    padding: 0px 9px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 700;
    color: #828282;
}
.web .web__content .web__box .web__box-content .date__list .date__items {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item {
    width: 80px;
    height: 80px;
    margin-right: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: solid 2px #ed8154;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item:hover {
    background-color: #ed8154;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item:hover
    span {
    color: #fff;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item:hover
    h5 {
    color: #fff;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item
    span {
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    text-decoration: underline;
}
.web
    .web__content
    .web__box
    .web__box-content
    .date__list
    .date__items
    .date__item
    h5 {
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    padding-top: 14px;
    text-align: center;
}
.web .web__content .web__box .web__box-content .time__list {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.web .web__content .web__box .web__box-content .time__list .time__item {
    width: 80px;
    height: 40px;
    border-radius: 8px;
    border: solid 2px #ed8154;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}
.web .web__content .web__box .web__box-content .time__list .time__item:hover {
    background-color: #ed8154;
    color: #fff;
}
.web .web__content .web__box .web__box-button {
    width: 100%;
    height: 40px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.web .web__content .web__box .web__box-button a {
    flex-basis: 100%;
    font-size: 16px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-weight: 400;
    background-color: #ed8154;
}
.web .web__content .web__box .web__box-button a.button--empty {
    border: 2px solid #ed8154;
    background-color: #fff;
    color: #ed8154;
}
.web .web__content .web__box .web__box-button.web__box-button--double a {
    flex-basis: 50%;
}
.web
    .web__content
    .web__box
    .web__box-button.web__box-button--double
    a:first-child {
    border-bottom-left-radius: 8px;
}
.web .web__content .extra__box {
    width: 400px;
    height: auto;
    padding: 60px 40px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    text-align: center;
    margin-left: 60px;
}
.web .web__content .extra__box img.cappo {
    margin-top: 10px;
    margin-bottom: 20px;
}
.web .web__content .extra__box h5 {
    font-size: 18px;
    font-weight: 700;
    color: #417ac7;
}
.web .web__content .extra__box img:first-child {
    margin: 10px 0px;
}
.web .web__content .extra__box .extra__box-info-list {
    width: 100%;
    height: auto;
    border-top: 1px solid #ed8154;
}
.web .web__content .extra__box .extra__box-info-list ul {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #ed8154;
    display: flex;
    padding: 10px 0px;
}
.web .web__content .extra__box .extra__box-info-list ul li {
    display: inline-flex;
    width: 50%;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}
.web .web__content .extra__box .extra__box-info-list ul li:nth-child(2n) {
    border-left: 1px solid #ed8154;
}
.web .web__content .extra__box .extra__box-info-list ul li .info__list-top {
    width: 100%;
    height: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    ul
    li
    .info__list-top
    span {
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
}
.web .web__content .extra__box .extra__box-info-list ul li .info__list-top img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    margin-right: 10px;
}
.web .web__content .extra__box .extra__box-info-list ul li p {
    font-size: 14px;
    text-align: left;
    color: #4f4f4f;
}
.web .web__content .extra__box .extra__box-info-list ul li .item-action {
    width: 25px;
    height: 25px;
}
.web .web__content .extra__box .extra__box-info-list ul li .item-action img {
    width: 25px;
    height: 25px;
    margin: 0px;
    margin-left: 5px;
}
.web .web__content .extra__box .extra__box-info-list .info__list-contact {
    width: 100%;
    height: auto;
    padding: 15px 0px;
    border-bottom: 1px solid #ed8154;
}
.web .web__content .extra__box .extra__box-info-list .info__list-contact h6 {
    font-size: 16px;
    font-weight: 500;
    color: #417ac7;
    padding-bottom: 15px;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item {
    width: 100%;
    height: auto;
    padding: 5px 0px;
    display: flex;
    align-items: flex-start;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item
    .list-contact-item-content {
    display: inline-flex;
    flex-wrap: wrap;
    padding-left: 10px;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item
    .list-contact-item-content
    b {
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    width: 100%;
    height: auto;
    text-align: left;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item
    .list-contact-item-content
    span {
    padding-top: 8px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #417ac7;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item
    .list-contact-item-content
    .item-action {
    margin-top: 3px;
    margin-left: 8px;
}
.web
    .web__content
    .extra__box
    .extra__box-info-list
    .info__list-contact
    .info__list-contact-item
    .list-contact-item-content
    .item-action
    img {
    width: 25px;
    height: 25px;
    margin: 0px;
    display: inline-block;
}
.web .web__content .extra__box .extra__box-info-list .info__list-button {
    width: 100%;
    height: auto;
    padding-top: 47px;
}
.web .web__content .extra__box .extra__box-info-list .info__list-button button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 0px;
}

/*
APP STARTS HERE
*/
.app {
    width: 100%;
    height: auto;
    min-height: 100vh;
    position: relative;
    background-color: #e4e4e4;
    display: flex;
    align-items: flex-start;
}
.app .app__header {
    width: 100%;
    height: 48px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
}
.app .app__header .header__logo {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px;
}
.app .app__header .header__logo img {
    height: 17px;
    max-width: 100%;
}
.app .app__header .header__actions {
    position: relative;
}
.app .app__header .header__actions .actions__head {
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.app .app__header .header__actions:hover > .actions__content {
    display: block;
}
.app .app__header .header__actions .actions__content {
    position: absolute;
    top: 30px;
    right: 0px;
    width: 220px;
    height: auto;
    border-radius: 5px;
    padding: 7px 15px;
    background-color: #fff;
    display: none;
}
.app .app__header .header__actions .actions__content ul li {
    display: inline-flex;
    align-items: center;
    padding: 7px 0px;
    width: 100%;
    height: auto;
}
.app .app__header .header__actions .actions__content ul li a {
    font-size: 14px;
    color: #303030;
    font-weight: 400;
}
.app .app__header .header__actions .actions__content ul li a:hover {
    color: #ed8154;
}
.app .app__header .header__actions .actions__content ul li h5 {
    font-size: 14px;
    font-weight: 700;
    color: #303030;
}
.app .app__sidebar {
    width: 240px;
    min-width: 240px;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding-top: 48px;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 8;
}
.app .app__sidebar .sidebar__nav {
    width: 100%;
    height: auto;
    border-top: 1px solid #e0e0e0;
    padding: 8px 30px;
}
.app .app__sidebar .sidebar__nav ul li {
    width: 100%;
    height: auto;
    padding: 8px 0px;
    list-style: none;
}
.app .app__sidebar .sidebar__nav ul li.sidebar__nav-head {
    padding: 7px 0px;
}
.app .app__sidebar .sidebar__nav ul li.sidebar__nav-head b {
    font-size: 14px;
    font-weight: 700;
    color: #828282;
}
.app .app__sidebar .sidebar__nav ul li a {
    display: inline-flex;
    align-items: center;
}
.app .app__sidebar .sidebar__nav ul li a img {
    width: 18px;
    margin-right: 22px;
    display: inline-flex;
}
.app .app__sidebar .sidebar__nav ul li a span {
    font-size: 14px;
    color: #828282;
    font-weight: 400;
}

.app .app__sidebar .sidebar__nav ul li:hover > a span {
    color: #ed8154;
}
.app .app__content {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 40px;
    padding-top: 88px;
    padding-left: 280px;
    padding-bottom: 65px;
}
.app .app__content.wout-sidebar {
    padding-left: 40px;
}
.app .app__content .settings {
    width: 100%;
    height: auto;
}
.app .app__content .settings .breadcrumb {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
.app .app__content .settings .breadcrumb ul {
    display: flex;
    margin: 0px;
}
.app .app__content .settings .breadcrumb ul li {
    display: inline-flex;
    margin: 0px;
    padding: 0px;
    margin-right: 10px;
}
.app .app__content .settings .breadcrumb ul li a {
    position: relative;
    padding: 0px 20px;
    height: 40px;
    background-color: #fff;
    border: 2px solid #ed8154;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
    color: #ed8154;
    font-size: 10px;
    font-weight: 700;
    text-decoration: none;
    padding-right: 20px;
}
.app .app__content .settings .breadcrumb ul li a::before {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: -15px;
    top: 4px;
    width: 28px;
    height: 28px;
    background-color: #e4e4e4;
    border-bottom: 2px solid #ed8154;
    border-right: 2px solid #ed8154;
    transform: rotate(-45deg);
    z-index: 8;
    flex-wrap: wrap;
}
.app .app__content .settings .breadcrumb ul li a::after {
    content: "";
    position: absolute;
    box-sizing: border-box;
    left: 100%;
    top: 4px;
    width: 28px;
    height: 28px;
    background-color: #fff;
    border-bottom: 2px solid #ed8154;
    border-right: 2px solid #ed8154;
    transform: rotate(-45deg);
    margin-left: -12px;
    z-index: 9;
}
.app .app__content .settings .breadcrumb ul li:first-child a {
    padding-left: 20px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}
.app .app__content .settings .breadcrumb ul li:first-child a::before {
    display: none;
}
.app .app__content .settings .breadcrumb ul li:last-child a {
    color: #fff;
    background-color: #ed8154;
}
.app .app__content .settings .breadcrumb ul li:last-child a::after {
    background-color: #ed8154;
}
.app .app__content .settings .settings__list {
    width: 100%;
    height: auto;
}
.app .app__content .settings .settings__list .settings__list-header {
    width: 100%;
    height: auto;
}
.app .app__content .settings .settings__list .settings__list-header h4 {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
}
.app .app__content .settings .settings__list .settings__list-items {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item {
    flex-basis: calc(33.33% - 13.33px);
    height: auto;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ed8154;
    background-color: #fcf6ef;
    overflow: hidden;
    margin: 20px 0px;
    margin-right: 20px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item:nth-child(3n) {
    margin-right: 0px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-head {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ed8154;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-head
    h4 {
    font-size: 14px;
    font-weight: 700;
    color: #ed8154;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content {
    padding: 18px 22px;
    height: 180px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    p {
    font-size: 14px;
    color: #ed8154;
    line-height: 24px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .inline__list {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .inline__list
    .inline__list-item {
    min-width: 120px;
    width: auto;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .inline__list
    .inline__list-item
    h5 {
    font-size: 32px;
    font-weight: 700;
    color: #ed8154;
    line-height: 24px;
    margin-bottom: 8px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .inline__list
    .inline__list-item
    span {
    font-size: 10px;
    color: #ed8154;
    display: flex;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .inline__list
    .inline__list-item
    h3 {
    font-size: 32px;
    font-weight: 700;
    color: #982727;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .normal__list
    ul
    li {
    display: inline-flex;
    align-items: center;
    margin-bottom: 8px;
    width: 100%;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .normal__list
    ul
    li
    b {
    width: 115px;
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    margin-right: 36px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-content
    .normal__list
    ul
    li
    span {
    font-size: 14px;
    font-weight: 400;
    color: #ed8154;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button {
    width: 100%;
    height: 40px;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button
    a {
    width: 100%;
    display: inline-flex;
    height: 40px;
    background-color: #ed8154;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
}

.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button
    a:hover {
    opacity: 0.8;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button
    a.button--white {
    background-color: #fff;
    border-top: 1px solid #ed8154;
    color: #ed8154;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button.settings__list-item-button-double {
    display: flex;
}
.app
    .app__content
    .settings
    .settings__list
    .settings__list-items
    .settings__list-item
    .settings__list-item-button.settings__list-item-button-double
    a {
    width: 50%;
}
.app .app__content .settings .settings__company {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
}
.app .app__content .settings .settings__company .settings__company-left {
    flex-basis: calc(50% - 10px);
    min-width: calc(50% - 10px);
    margin-right: 10px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 20px 40px;
}
.app .app__content .settings .settings__company .settings__company-left h4 {
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__input {
    width: 100%;
    height: auto;
    padding-top: 18px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__input
    label {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 700;
    display: inline-flex;
    padding-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__input
    input,
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__input
    textarea {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    outline: none;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__input
    textarea {
    height: 80px;
    resize: none;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload {
    width: 100%;
    height: auto;
    padding-top: 18px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    h5 {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 700;
    display: inline-flex;
    padding-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .placeholder {
    width: 100%;
    height: auto;
    padding-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .placeholder
    .placeholder__image {
    width: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8a8686;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .custom__upload-buttons {
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .custom__upload-buttons
    input {
    display: none;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .custom__upload-buttons
    label {
    padding: 4px 23px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    margin-right: 10px;
    cursor: pointer;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-left
    .custom__upload
    .custom__upload-buttons
    a {
    display: inline-flex;
    padding: 4px 23px;
    border-radius: 8px;
    background-color: #982727;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
}
.app .app__content .settings .settings__company .settings__company-right {
    flex-basis: calc(50% - 10px);
    margin-left: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 20px 40px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    h4 {
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
    padding-bottom: 8px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .current__plan {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .current__plan
    h3 {
    font-size: 28px;
    color: #ed8154;
    font-weight: 400;
    margin-right: 80px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .current__plan
    a {
    padding: 4px 23px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 10px;
    color: #fff;
    font-weight: 700;
    margin-right: 10px;
    cursor: pointer;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info {
    width: 100%;
    height: auto;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info
    .plan__info-item {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    padding: 10px 0px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info
    .plan__info-item
    b {
    min-width: 80px;
    margin-right: 20px;
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 700;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info
    .plan__info-item
    p {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 400;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info
    .plan__info-item.plan__info-item-two {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__info
    .plan__info-item.plan__info-item-two
    .plan__info-item-double {
    flex-basis: 50%;
    display: flex;
    align-items: flex-start;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__button {
    width: 100%;
    height: auto;
    margin-top: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-plan
    .plan__button
    a {
    padding: 10px 54px;
    height: 40px;
    border-radius: 8px;
    background-color: #ed8154;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 25px 40px;
    margin-top: 21px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    h4 {
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 500;
    margin-bottom: 18px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .custom__input {
    width: 100%;
    height: auto;
    margin-bottom: 18px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .custom__input
    label {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 700;
    display: inline-flex;
    padding-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .custom__input
    input,
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .custom__input
    textarea {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    outline: none;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .custom__input
    textarea {
    height: 80px;
    resize: none;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
    justify-content: space-between;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list
    h5 {
    font-size: 14px;
    font-weight: 700;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list
    .payment__list-item {
    flex-basis: calc(25% - 15px);
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list
    .payment__list-item
    img {
    height: 40px;
    max-width: 100%;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list
    .payment__list-item
    .custom__select {
    width: 100%;
    height: auto;
}
.app
    .app__content
    .settings
    .settings__company
    .settings__company-right
    .settings__company-payment
    .payment__list
    .payment__list-item
    .custom__select
    select {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    outline: none;
}
.app .app__content .settings .settings__um-top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.app .app__content .settings .settings__um-top .breadcrumb {
    width: auto;
    margin-bottom: 0px;
}
.app .app__content .settings .settings__um-top .settings__um-top-actions {
    display: inline-flex;
    align-items: center;
    margin-left: 50px;
}
.app .app__content .settings .settings__um-top .settings__um-top-actions a {
    padding: 10px 45px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    display: inline-flex;
    margin-right: 20px;
}
.app .app__content .settings .settings__um-top .settings__um-top-actions form {
    width: 300px;
    height: 40px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}
.app
    .app__content
    .settings
    .settings__um-top
    .settings__um-top-actions
    form
    input {
    width: 100%;
    height: 40px;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 52px;
    border-radius: 8px;
    outline: none;
}
.app
    .app__content
    .settings
    .settings__um-top
    .settings__um-top-actions
    form
    button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ed8154;
    border: 0px;
}
.app
    .app__content
    .settings
    .settings__um-top
    .settings__um-top-actions
    form
    button
    img {
    width: 17.5px;
    height: 17.5px;
    object-fit: contain;
}
.app .app__content .settings .settings__um-options {
    width: 100%;
    height: 20px;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.app .app__content .settings .settings__um-options .custom__checkbox {
    display: inline-flex;
    align-items: center;
    margin-right: 40px;
}
.app
    .app__content
    .settings
    .settings__um-options
    .custom__checkbox
    .custom__checkbox-container {
    height: 18px;
    margin: 0px;
}
.app .app__content .settings .settings__um-options .custom__checkbox h5 {
    font-size: 14px;
    color: #828282;
    font-weight: 700;
    margin-left: 0px;
}
.app .app__content .settings .settings__um-options .custom__option {
    display: inline-flex;
    align-items: center;
}
.app .app__content .settings .settings__um-options .custom__option img {
    width: 18.7px;
    height: 19.2px;
    object-fit: contain;
}
.app
    .app__content
    .settings
    .settings__um-options
    .custom__option
    img:last-child {
    width: 10px;
    height: 10px;
}
.app .app__content .settings .settings__um-options .custom__option span {
    font-size: 14px;
    color: #ed8154;
    font-weight: 400;
    padding: 0px 10px;
}
.app .app__content .settings .settings__um-list {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
}
.app .app__content .settings .settings__um-list .settings__um-out {
    width: calc(33.33% - 13.33px);
    height: auto;
    position: relative;
    margin-right: 20px;
    margin-top: 20px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-out:nth-child(3n) {
    margin-right: 0px;
}
.app .app__content .settings .settings__um-list .settings__um-item {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ed8154;
    background-color: #fcf6ef;
    /* overflow-y: hidden; */
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    border-bottom: 1px solid #ed8154;
    background-color: #fff;
    position: relative;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top
    em {
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    min-width: 40px;
    margin-right: 20px;
    display: inline-flex;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top
    em.color--green {
    color: #28602e;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top
    em.color--red {
    color: #982727;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top
    h5 {
    font-size: 16px;
    font-weight: 500;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__top
    .custom__checkbox {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 18px;
    height: 18px;
    transform: translateY(-50%);
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content {
    width: 100%;
    height: auto;
    display: flex;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left {
    width: 100%;
    height: auto;
    padding: 20px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left
    ul
    li {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left
    ul
    li:last-child {
    margin-bottom: 0px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left
    ul
    li
    span {
    width: 100px;
    display: inline-flex;
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
    line-height: 19px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left
    ul
    li
    span.linked {
    color: #417ac7;
    text-decoration: underline;
    font-weight: 500;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-left
    ul
    li
    em {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
    font-style: normal;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right {
    min-width: 130px;
    height: auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    border-left: solid 1px #ed8154;
    background-color: #fff;
    border-bottom-right-radius: 8px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action {
    width: 86px;
    height: auto;
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 10px;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action:last-child {
    margin-bottom: 0px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action
    span {
    font-size: 14px;
    font-weight: 500;
    color: #ed8154;
    padding-bottom: 5px;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action:hover
    > span {
    color: #000;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action
    span.color--red {
    color: #982727;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action.active
    .action__list {
    display: block;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action {
    position: relative;
    cursor: pointer;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action
    .action__list {
    position: absolute;
    display: none;
    top: 20px;
    /* bottom: -120px; */
    right: 0px;
    width: 160px;
    height: auto;
    border: 1px solid #ed8154;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px 10px;
    z-index: 99;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action
    .action__list
    ul
    li {
    list-style: none;
    width: 100%;
    height: auto;
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
    cursor: pointer;
}
.app
    .app__content
    .settings
    .settings__um-list
    .settings__um-item
    .umitem__content
    .umitem__content-right
    .umitem__content-action
    .action__list
    ul
    li.color--red {
    color: #982727;
}
.app .app__content .settings .settings__translations {
    width: 100%;
    height: auto;
}
.app .app__content .settings .settings__translations .translations__top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__top
    .breadcrumb {
    width: auto;
    margin: 0px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__top
    .translations__top-actions {
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__top
    .translations__top-actions
    a {
    display: inline-flex;
    padding: 10px 31px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-left: 20px;
}
.app .app__content .settings .settings__translations .translations__block {
    width: 100%;
    height: auto;
    padding-top: 40px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box {
    width: 100%;
    max-width: 1250px;
    height: auto;
    border-radius: 8px;
    padding-bottom: 10px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form {
    width: 100%;
    height: auto;
    padding: 40px;
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    select {
    min-width: 250px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    .tf-img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin: 0px 20px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    form {
    width: 100%;
    height: 40px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 20px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    form
    input {
    width: 100%;
    height: 40px;
    border: 1px solid #bdbdbd;
    background-color: #fff;
    padding-left: 12px;
    padding-right: 52px;
    border-radius: 8px;
    outline: none;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    form
    button {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ed8154;
    border: 0px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__form
    form
    button
    img {
    width: 17.5px;
    height: 17.5px;
    object-fit: contain;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs {
    width: 100%;
    height: auto;
    border-bottom: 1px solid #bdbdbd;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs
    ul
    li {
    width: 104px;
    height: 64px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 80px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs
    ul
    li.active {
    border-bottom: 4px solid #ed8154;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs
    ul
    li.active
    h4 {
    color: #ed8154;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs
    ul
    li
    h4 {
    font-size: 24px;
    font-weight: 300;
    color: #4f4f4f;
    line-height: 32px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__tabs
    ul
    li
    b {
    font-size: 14px;
    font-weight: 700;
    padding-top: 3px;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content {
    width: 100%;
    height: auto;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list {
    width: 100%;
    height: auto;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item {
    width: 100%;
    height: auto;
    padding: 13px 30px;
    display: flex;
    border-top: solid 1px #ed8154;
    background-color: #fff;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item:first-child {
    border-top: 0px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item.head--item {
    padding-top: 40px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item.head--item
    .ti__small {
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item.head--item
    .ti__small:last-child {
    justify-content: flex-start;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item.head--item
    .ti__small
    .custom__checkbox {
    height: 18px;
    margin-left: 10px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    .ti__small {
    width: 10%;
    padding: 0px 5px;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    .ti__small:last-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    .ti__small
    h4 {
    font-size: 14px;
    font-weight: 700;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    .ti__big {
    padding: 0px 5px;
    width: 40%;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    .ti__big
    p {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app
    .app__content
    .settings
    .settings__translations
    .translations__block
    .translations__box
    .translations__content
    .translations__list
    .translations__item
    h5 {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
}
.app .app__content .settings .settings__questions {
    width: 100%;
    height: auto;
}
.app .app__content .settings .settings__questions .questions__top {
    width: 100%;
    height: auto;
}
.app .app__content .settings .settings__questions .questions__block {
    width: 100%;
    height: auto;
    padding-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 40px;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .questions__arrows {
    display: flex;
    flex-direction: column;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .questions__arrows
    img {
    width: 9.2px;
    height: 18px;
    object-fit: contain;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .custom__input {
    padding-top: 18px;
    margin-left: 10px;
    width: 600px;
    min-width: 250px;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .custom__input
    label {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 400;
    display: inline-flex;
    padding-bottom: 10px;
    width: 600px;
    max-width: 100%;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .custom__input
    input,
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .custom__input
    textarea {
    width: 100%;
    max-width: 600px;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    outline: none;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .custom__input
    textarea {
    height: 80px;
    resize: none;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .question__actions {
    display: inline-flex;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-input
    .question__actions
    a {
    margin-left: 20px;
    font-size: 14px;
    color: #417ac7;
    font-weight: 500;
    text-decoration: underline;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-button {
    width: 100%;
    height: auto;
    padding-top: 18px;
}
.app
    .app__content
    .settings
    .settings__questions
    .questions__block
    .custom__question-button
    a {
    margin-left: 20px;
    font-size: 14px;
    color: #417ac7;
    font-weight: 500;
    text-decoration: underline;
}
.app .app__content .settings.event__settings-tab .event__settings-tab-top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .breadcrumb {
    margin: 0px;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons {
    display: flex;
    align-items: center;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    .custom__select {
    width: 280px;
    height: 40px;
    position: relative;
    margin-right: 20px;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    .custom__select
    select {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    padding-right: 55px;
    border-radius: 8px;
    border: solid 1px #828282;
    background-color: #fff;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    .custom__select
    .custom__select-button {
    width: 40px;
    height: 40px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #4f4f4f;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    .custom__select
    .custom__select-button:hover {
    background-color: #ed8154;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    button {
    border: 0px;
    width: 200px;
    height: 40px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    margin-right: 20px;
    cursor: pointer;
}

.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    button:hover {
    opacity: 0.8;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    button:last-child {
    margin: 0px;
}
.app
    .app__content
    .settings.event__settings-tab
    .event__settings-tab-top
    .event__settings-buttons
    button.button__small {
    width: auto;
    padding: 0px 10px;
    display: inline-flex;
    align-items: center;
}
.app .app__content .event__overview .event__overview-top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    padding-bottom: 74px;
}
.app .app__content .event__overview .event__overview-top button {
    width: 310px;
    height: 40px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 0px;
    margin-right: 20px;
    cursor: pointer;
}
.app .app__content .event__overview .event__overview-top button:hover {
    opacity: 0.8;
}
.app .app__content .event__overview .event__overview-top select {
    width: 310px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding: 0px 15px;
    margin-right: 20px;
}
.app .app__content .event__overview .event__overview-title {
    width: 100%;
    height: auto;
}
.app .app__content .event__overview .event__overview-title h5 {
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}
.app .app__content .event__times {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 38px 40px;
}
.app .app__content .event__times .event__times--save button {
    padding: 10px 24px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 0px;
    width: 100%;
    cursor: pointer;
    margin-top: 15px;
}
.app .app__content .event__times .event__times--save button:hover {
    opacity: 0.8;
}
.app .app__content .event__times .event__times-title {
    width: 100%;
    height: auto;
}
.app .app__content .event__times .event__times-title h4 {
    font-size: 14px;
    font-weight: bold;
    color: #828282;
}
.app .app__content .event__times .event__times-top {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
}
.app .app__content .event__times .event__times-top .event__times-top-left span {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app .app__content .event__times .event__times-top .event__times-top-right {
    display: flex;
    align-items: center;
}
.app
    .app__content
    .event__times
    .event__times-top
    .event__times-top-right
    .custom__select {
    width: 310px;
    height: 40px;
}
.app
    .app__content
    .event__times
    .event__times-top
    .event__times-top-right
    .custom__select:first-child {
    margin-right: 20px;
}
.app
    .app__content
    .event__times
    .event__times-top
    .event__times-top-right
    .custom__select
    select {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
}
.app .app__content .event__times .event__times-boxes {
    width: 100%;
    height: auto;
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
}
.app .app__content .event__times .event__times-boxes .event__times-box {
    width: calc(50% - 10px);
    height: auto;
    border-radius: 8px;
    border: solid 1px #ed8154;
    background-color: #fcf5ed;
    padding: 13px 19px;
    margin-bottom: 20px;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    b {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options {
    width: auto;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options:hover
    .et__options-list {
    display: block;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    span {
    font-size: 10px;
    height: 20px;
    color: #757575;
    font-weight: 500;
    margin: 0px 7px;
    display: inline-flex;
    align-items: center;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    img:first-child {
    margin-right: 7px;
    height: 12px;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    img:last-child {
    margin-left: 7px;
    height: 6.2px;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    .et__options-list {
    position: absolute;
    top: 20px;
    right: 0px;
    width: 160px;
    height: auto;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    padding: 0px 10px;
    display: none;
    z-index: 99;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    .et__options-list
    ul {
    width: 100%;
    height: auto;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    .et__options-list
    ul
    li {
    width: 100%;
    height: auto;
    padding: 3px 0px;
    font-size: 12px;
    font-weight: 400;
    color: #4f4f4f;
    cursor: pointer;
    list-style: none;
    cursor: pointer;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    .et__options-list
    ul
    li:hover {
    color: #ed8154;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-top
    .et__options
    .et__options-list
    ul
    li.color--red {
    color: #982727;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items {
    width: 100%;
    height: auto;
    padding: 8px 20px;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    h5 {
    font-size: 14px;
    font-weight: 500;
    color: #757575;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__select {
    width: 70px;
    height: auto;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__select
    select {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bdbdbd;
    background-color: #fff;
    padding-left: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__separator {
    min-width: 20px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__medium {
    width: 160px;
    display: flex;
    align-items: center;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__small {
    width: 70px;
    display: inline-flex;
    justify-content: space-between;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__small:last-child
    div {
    cursor: pointer;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__small
    .eti__members
    img {
    filter: invert(43%) sepia(9%) saturate(339%) hue-rotate(192deg)
        brightness(95%) contrast(86%);
    width: 20px;
}

.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__small
    .eti__members.eti__members--green
    img {
    filter: invert(50%) sepia(31%) saturate(497%) hue-rotate(114deg)
        brightness(90%) contrast(95%);
}

.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-items
    .event__times-box-item
    .eti__small
    .eti__members.eti__members--orange
    img {
    filter: invert(61%) sepia(11%) saturate(2404%) hue-rotate(331deg)
        brightness(96%) contrast(93%);
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-item-add {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-item-add
    a {
    font-size: 14px;
    font-weight: 500;
    color: #417ac7;
    text-decoration: underline;
}

.app
    .app__content
    .event__times
    .event__times-boxes
    .event__times-box
    .event__times-box-item-add
    a:hover {
    color: #ed8154;
}
.app .app__content .event__times .event__times-add-box {
    width: 100%;
    height: auto;
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app .app__content .event__times .event__times-add-box a {
    font-size: 14px;
    font-weight: 500;
    color: #417ac7;
    text-decoration: underline;
}
.app .app__content .event__times .event__times-add-box a:hover {
    color: #ed8154;
}
.app .app__content .project__tabs {
    width: 100%;
    height: auto;
    background-color: #fff;
    border: 3px;
    display: flex;
    align-items: center;
}

.app .app__content .project__tabs .project__tab {
    height: 100%;
    padding: 15px 20px;
    border-top: 2px solid #d7d7d7;
    font-size: 14px;
    cursor: pointer;
}
.app .app__content .project__tabs .project__tab:hover {
    border-top: 2px solid #ed8154;
}
.app .app__content .project__tabs .project__tab.active {
    border-top: 2px solid #ed8154;
    color: #ed8154;
}
.app .app__content .project__appointment {
    width: 100%;
    height: auto;
    border-radius: 2px;
    border: solid 1px #ed8154;
    background-color: #fff;
}
.app .app__content .project__appointment.pma {
    display: none;
}
.app .app__content .project__appointment.pma.active {
    display: block;
}
.app .app__content .project__appointment .pa__table {
    width: 100%;
    height: auto;
}
.app .app__content .project__appointment .pa__table .pa__table-row {
    width: 100%;
    height: auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ed8154;
}
.app .app__content .project__appointment .pa__table .pa__table-row:last-child {
    border-bottom: 0px;
}
.app
    .app__content
    .project__appointment
    .pa__table
    .pa__table-row:nth-child(even) {
    background-color: #f2f2f2;
}
.app .app__content .project__appointment .pa__table .pa__table-row h5 {
    font-size: 14px;
    font-weight: bold;
    color: #828282;
}
.app .app__content .project__appointment .pa__table .pa__table-row h4 {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
    padding: 2px 0px;
}
.app .app__content .project__appointment .pa__table .pa__table-row button {
    padding: 2px 10px;
    border-radius: 2px;
    background-color: #ed8154;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    border: 0px;
    cursor: pointer;
}

.app .app__content .project__appointment .pa__table .pa__table-row .pa__big {
    flex: 3;
}
.app .app__content .project__appointment .pa__table .pa__table-row .pa__medium {
    flex: 2;
}
.app .app__content .project__appointment .pa__table .pa__table-row .pa__small {
    flex: 1;
}
.app
    .app__content
    .project__appointment
    .pa__table
    .pa__table-row
    .pa__small
    button:hover {
    background-color: #303030;
}
.app
    .app__content
    .project__appointment
    .pa__table
    .pa__table-row
    .pa__small
    .custom__checkbox {
    position: relative;
    margin-top: 1px;
    width: 100%;
    height: 30px;
    justify-content: center;
}
.app .app__content .event__notifications {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 40px;
}
.app .app__content .event__notifications .event__notifications-title {
    width: 100%;
    height: auto;
}
.app .app__content .event__notifications .event__notifications-title h3 {
    font-size: 16px;
    color: #707070;
    font-weight: 500;
}
.app .app__content .event__notifications .event__notifications-items {
    width: 100%;
    height: auto;
    padding-top: 18px;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 0px;
    border-bottom: 1.5px solid #707070;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item:last-child {
    border-bottom: 0px;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-content {
    flex: 3;
    display: flex;
    flex-direction: column;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-content
    h4 {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    padding-bottom: 10px;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-content
    p {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-checks {
    flex: 2;
    display: flex;
    align-items: center;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-checks
    .checks__switch {
    display: flex;
    align-items: center;
    margin: 0px 20px;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-checks
    .checks__switch
    h5 {
    font-size: 14px;
    font-weight: 500;
    color: #4f4f4f;
    margin-left: 10px;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-button {
    flex: 1;
}
.app
    .app__content
    .event__notifications
    .event__notifications-items
    .event__notifications-item
    .event__notifications-item-button
    button {
    padding: 10px 24px;
    border-radius: 8px;
    background-color: #ed8154;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border: 0px;
}

.app .app__right {
    width: 370px;
    min-width: 370px;
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    background-color: #fff;
    padding-top: 48px;
    border-radius: 2px;
    box-shadow: -2px 0 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    position: fixed;
    top: 0px;
    right: 0px;
    transition: transform 0.2s ease-in-out;
    z-index: 9999;
}
.app .app__right.app__right--hidden {
    transform: translateX(380px);
}
.app .app__right .app__right-content {
    width: 100%;
    height: auto;
    padding: 14px 0px;
}
.app .app__right .app__right-content .app__right-box {
    width: 100%;
    height: auto;
    padding: 20px;
    border-bottom: 1px solid #e0e0e0;
}
.app .app__right .app__right-content .app__right-box:last-child {
    border-bottom: 0px;
}
.app .app__right .app__right-content .app__right-box h3 {
    font-size: 20px;
    font-weight: bold;
    color: #303030;
    margin-bottom: 15px;
}
.app .app__right .app__right-content .app__right-box .custom__select {
    width: 100%;
    height: auto;
    padding: 5px 0px;
}
.app .app__right .app__right-content .app__right-box .custom__select select {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
}
.app .app__right .app__right-content .app__right-box .custom__search {
    width: 100%;
    height: 40px;
    margin: 10px 0px;
    position: relative;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background-color: #fff;
    padding-right: 40px;
    overflow: hidden;
}
.app .app__right .app__right-content .app__right-box .custom__search input {
    width: 100%;
    height: 40px;
    border: 0px;
    padding-left: 15px;
    outline: none;
}
.app .app__right .app__right-content .app__right-box .custom__search .cs__img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ed8154;
    display: flex;
    align-items: center;
    justify-content: center;
}
.app .app__right .app__right-content .app__right-box h4 {
    font-size: 18px;
    font-weight: 500;
    color: #707070;
}
.app .app__right .app__right-content .app__right-box ul {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    margin-top: 20px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}
.app .app__right .app__right-content .app__right-box ul li {
    width: 50%;
    display: inline-flex;
    height: auto;
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app .app__right .app__right-content .app__right-box ul li.full-li {
    width: 100%;
    font-size: 16px;
    color: #4f4f4f;
    font-weight: 500;
}
.app .app__right .app__right-content .app__right-box.app__right-box-pam {
    height: 100%;
}
.app .app__right .app__right-content .app__right-box.app__right-box-pam h4 {
    padding-top: 35px;
}
.app
    .app__right
    .app__right-content
    .app__right-box.app__right-box-pam
    h4:first-child {
    padding-top: 0px;
}
.app
    .app__right
    .app__right-content
    .app__right-box.app__right-box-pam
    .custom__select {
    padding: 10px 0px;
}
.app .app__right .app__right-content .app__right-box .app__right-box-delete {
    position: absolute;
    left: 0px;
    bottom: 70px;
    padding: 0px 20px;
    width: 100%;
    height: auto;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .app__right-box-delete
    button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    background-color: #982727;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.app .app__right .app__right-content .app__right-box .app__right-box-button {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .app__right-box-button
    button {
    width: calc(50% - 10px);
    height: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: #ed8154;
    border: 0px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}
.app .app__right .app__right-content .app__right-box .custom__check {
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 0px;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .custom__check
    .custom__check-content {
    padding-left: 0px;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .custom__check
    .custom__check-content
    h5 {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 400;
    padding-bottom: 5px;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .custom__check
    .custom__check-content
    .custom__check-buttons {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .custom__check
    .custom__check-content
    .custom__check-buttons
    button {
    padding: 3px 16px;
    border-radius: 8px;
    background-color: #ed8154;
    border: 0px;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    margin-right: 10px;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .custom__check
    .custom__check-content
    .custom__check-buttons
    button.cc--delete {
    background-color: #982727;
}
.app .app__right .app__right-content .app__right-box .new__observer {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
}
.app .app__right .app__right-content .app__right-box .new__observer a {
    font-size: 14px;
    font-weight: 500;
    color: #417ac7;
    text-decoration: underline;
}
.app .app__right .app__right-content .app__right-box .invoice__history-list {
    width: 100%;
    height: auto;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .invoice__history-list
    .invoice__history-list-item {
    width: 100%;
    height: auto;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .invoice__history-list
    .invoice__history-list-item
    span {
    font-size: 14px;
    color: #4f4f4f;
    font-weight: 400;
}
.app
    .app__right
    .app__right-content
    .app__right-box
    .invoice__history-list
    .invoice__history-list-item
    a {
    font-weight: 500;
    color: #417ac7;
    font-size: 14px;
}
.app .custom__checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.app .custom__checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.app .custom__checkbox-container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #eee;
}
.app .custom__checkbox-container:hover > input ~ .checkmark {
    background-color: #ccc;
}
.app .custom__checkbox-container input:checked ~ .checkmark {
    background-color: #ed8154;
    border-radius: 3px;
}
.app .custom__checkbox-container .checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.app .custom__checkbox-container input:checked ~ .checkmark:after {
    display: block;
}
.app .custom__checkbox-container .checkmark:after {
    left: 5px;
    top: 2px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.app .app__footer {
    width: 100%;
    height: 50px;
    background-color: #828282;
    left: 0px;
    bottom: 0px;
    position: fixed;
    padding: 0px 55px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
}
.app .app__footer .footer__left {
    display: inline-flex;
    align-items: center;
}
.app .app__footer .footer__left a {
    padding-right: 117px;
    color: #fff;
    font-size: 14px;
}
.app .app__footer .footer__right {
    display: inline-flex;
    align-items: center;
}
.app .app__footer .footer__right b {
    font-size: 14px;
    color: #fff;
    margin-right: 34px;
}
.app .app__footer .footer__right span {
    font-size: 14px;
    color: #fff;
}

.app__modal {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    display: none;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 9999999 !important;
}
.app__modal.active {
    display: flex;
}
.app__modal .app__modal-box {
    width: 100%;
    height: auto;
    max-width: 900px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
}
.app__modal .app__modal-box .app__modal-title {
    width: 100%;
    height: auto;
    padding: 10px 40px;
    background-color: #ed8154;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app__modal .app__modal-box .app__modal-title h4 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}
.app__modal .app__modal-box .app__modal-title img {
    cursor: pointer;
}
.app__modal .app__modal-box .app__modal-content {
    padding: 20px 0px;
}
.app__modal .app__modal-box .app__modal-content .personal__title {
    padding: 15px 40px;
}
.app__modal .app__modal-box .app__modal-content .personal__title h4 {
}
.app__modal .app__modal-box .app__modal-content .app__modal-search {
    width: 100%;
    height: auto;
    padding: 0px 40px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-search
    .app__modal-search-input {
    width: 100%;
    height: 40px;
    position: relative;
    border-radius: 8px;
    background-color: #fff;
    overflow: hidden;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-search
    .app__modal-search-input
    input {
    width: 100%;
    height: 40px;
    padding-left: 10px;
    border: 0px;
    border-radius: 8px;
    border: solid 1px #bdbdbd;
    outline: none;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-search
    .app__modal-search-input
    .s__img {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #ed8154;
}
.app__modal .app__modal-box .app__modal-content .app__modal-buttons {
    width: 100%;
    height: auto;
    padding: 0px 40px;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .custom__select {
    margin-right: 20px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .custom__select
    select {
    border-radius: 8px;
    border: solid 1px #ed8154;
    width: 200px;
    height: 40px;
    padding-left: 15px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-left {
    display: flex;
    align-items: center;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-right {
    display: flex;
    align-items: center;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-right
    .custom__check {
    display: flex;
    align-items: center;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-right
    .custom__check
    .custom__checkbox {
    display: flex;
    align-items: center;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-right
    .custom__check
    .custom__checkbox
    span {
    font-size: 14px;
    font-weight: bold;
    color: #828282;
    display: inline-flex;
    margin-right: 10px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-buttons
    .app__modal-buttons-right
    .custom__check
    .custom__checkbox
    label {
    margin-top: -5px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-table {
    width: 100%;
    height: auto;
    padding-top: 20px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-table .ap__row {
    width: 100%;
    height: auto;
    padding: 5px 40px;
    display: flex;
    align-items: center;
    border: 1px solid #ed8154;
    border-bottom: 0px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row:first-child {
    border: 0px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row:last-child {
    border-bottom: 1px solid #ed8154;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row:nth-child(even) {
    background-color: #f2f2f2;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .ap__medium {
    flex: 2;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .ap__big {
    flex: 3;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .ap__small {
    flex: 1;
}
.app__modal .app__modal-box .app__modal-content .app__modal-table .ap__row h5 {
    font-size: 14px;
    font-weight: bold;
    color: #828282;
}
.app__modal .app__modal-box .app__modal-content .app__modal-table .ap__row h4 {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .custom__select {
    padding-right: 20px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .custom__select
    select {
    border-radius: 8px;
    border: solid 1px #ed8154;
    width: 100%;
    height: 30px;
    padding-left: 15px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-table
    .ap__row
    .custom__checkbox {
    margin-top: -20px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-bottom {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 40px;
    position: relative;
    padding-top: 40px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-bottom .button__fl {
    position: absolute;
    left: 0px;
    bottom: 0px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-bottom button {
    border: 1px solid #ed8154;
    background-color: #ed8154;
    padding: 11px 54px;
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    cursor: pointer;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-bottom
    button.button-o {
    background-color: #fff;
    color: #ed8154;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-bottom
    button:hover {
    background-color: #303030;
    border: 1px solid #303030;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-bottom
    button.button-o:hover {
    background-color: #ed8154;
    border: 1px solid #ed8154;
    color: #fff;
}
.app__modal .app__modal-box .app__modal-content .app__modal-big-select {
    width: 100%;
    height: auto;
    padding: 0px 40px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-big-select label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    display: inline-flex;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-big-select
    .custom__select {
    width: 100%;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal-big-select
    .custom__select
    select {
    width: 100%;
    height: 40px;
    padding-left: 15px;
    border: 1px solid #ed8154;
    border-radius: 8px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-big-input {
    width: 100%;
    height: auto;
    padding: 0px 40px;
    margin-bottom: 20px;
}
.app__modal .app__modal-box .app__modal-content .app__modal-big-input label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    display: inline-flex;
}
.app__modal .app__modal-box .app__modal-content .app__modal-big-input input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
}
.app__modal .app__modal-box .app__modal-content .ea__list {
    width: 100%;
    height: auto;
    padding: 0px 40px;
}
.app__modal .app__modal-box .app__modal-content .ea__list ul li {
    width: 100%;
    height: auto;
    padding: 7px 0px;
    display: flex;
    align-items: flex-start;
    list-style: none;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li
    .ea__list-item-left {
    width: 250px;
    height: auto;
    display: flex;
    align-items: center;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li
    .ea__list-item-left
    b {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
    margin-left: 10px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li
    .ea__list-item-right
    span {
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
    width: 100%;
    display: block;
    padding-top: 10px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li
    .ea__list-item-right
    input {
    width: 200px;
    height: 35px;
    border: 1px solid #d7d7d7;
    padding-left: 10px;
    display: none;
}

.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li.active
    .ea__list-item-right
    span {
    display: none;
}

.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li.active
    .ea__list-item-right
    input {
    display: inline-block;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__list
    ul
    li
    .ea__list-item-right
    span:first-child {
    padding-top: 0px;
}
.app__modal .app__modal-box .app__modal-content .ea__selects {
    width: 100%;
    height: auto;
    padding: 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.app__modal .app__modal-box .app__modal-content .ea__selects .ea__select {
    width: calc(33.33% - 15px);
    height: auto;
}
.app__modal .app__modal-box .app__modal-content .ea__selects .ea__select label {
    width: 100%;
    height: auto;
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    margin-bottom: 10px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .ea__selects
    .ea__select
    select {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bdbdbd;
    background-color: #fff;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
}
.app__modal .app__modal-box .app__modal-content .app__modal--two {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
}
.app__modal .app__modal-box .app__modal-content .app__modal--two .custom__input,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__select {
    width: calc(50% - 10px);
    height: auto;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__input
    label,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__select
    label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 6px;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__input
    input,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__input
    select,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__select
    input,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__select
    select {
    width: 100%;
    height: 40px;
    border-radius: 2px;
    border: solid 1px #bdbdbd;
    background-color: #fff;
    padding-left: 15px;
    font-weight: 300;
    font-size: 14px;
    color: #4f4f4f;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__input
    select,
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--two
    .custom__select
    select {
    font-weight: 500;
}
.app__modal .app__modal-box .app__modal-content .custom__select-new {
    width: 100%;
    height: auto;
    padding: 0px 40px;
}
.app__modal .app__modal-box .app__modal-content .custom__select-new label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 6px;
}
.app__modal .app__modal-box .app__modal-content .custom__select-new select {
    width: 100%;
    height: 40px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
}
.app__modal .app__modal-box .app__modal-content .custom__input {
    width: 100%;
    height: auto;
    padding: 0px 40px;
}
.app__modal .app__modal-box .app__modal-content .custom__input label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 6px;
}
.app__modal .app__modal-box .app__modal-content .custom__input input {
    width: 100%;
    height: 40px;
    border: solid 1px #e0e0e0;
    background-color: #fff;
    border-radius: 4px;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #4f4f4f;
}
.app__modal .app__modal-box .app__modal-content .app__modal--textarea {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 40px;
    padding-top: 20px;
    flex-wrap: wrap;
}
.app__modal .app__modal-box .app__modal-content .app__modal--textarea label {
    font-size: 14px;
    font-weight: bold;
    color: #4f4f4f;
    width: 100%;
    height: auto;
    display: block;
    padding-bottom: 6px;
}
.app__modal .app__modal-box .app__modal-content .app__modal--textarea textarea {
    width: 100%;
    height: 370px;
    border-radius: 2px;
    border: solid 1px #bdbdbd;
    background-color: #fff;
    padding: 15px;
    font-weight: 300;
    font-size: 14px;
    color: #4f4f4f;
}
.app__modal
    .app__modal-box
    .app__modal-content
    .app__modal--textarea
    textarea.small {
    height: 78px;
}

.home {
    width: 100%;
    height: auto;
    padding-top: 20px;
}
.home .home__block {
    width: 100%;
    height: auto;
}
.home .home__block .home__info {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding-right: 56px;
}
.home .home__block .home__info h3 {
    font-size: 18px;
    font-weight: 700;
    color: #ed8154;
    line-height: 24px;
    padding-bottom: 10px;
}
.home .home__block .home__info ul {
    display: inline-flex;
}
.home .home__block .home__info ul li {
    list-style: none;
    color: #828282;
    font-size: 12px;
    line-height: 24px;
    margin-right: 80px;
}
.home .home__block .home__info p {
    padding: 0px;
    padding-top: 26px;
    font-size: 12px;
    color: #828282;
    line-height: 24px;
    font-weight: 300;
}
.home .home__block .home__info em {
    font-style: normal;
    color: #417ac7;
    font-weight: 700;
}

.page__pagination {
    width: 100%;
    height: auto;
    padding-top: 40px;
}
.page__pagination ul {
    width: 100%;
    height: auto;
    display: inline-flex;
}
.page__pagination ul li {
    list-style: none;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: solid 1px #e0e0e0;
    border-right: 0px;
    background-color: #fff;
    cursor: pointer;
}
.page__pagination ul li:last-child {
    border-right: solid 1px #e0e0e0;
}
.page__pagination ul li span {
    color: #4f4f4f;
    font-size: 14px;
    font-weight: 400;
}
.page__pagination ul li.active {
    background-color: #ed8154;
    border: 0px;
}
.page__pagination ul li.active span {
    color: #fff;
}
.page__pagination ul li:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.video__screen {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #fff;
    display: flex;
}
.video__screen .video__content {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video__screen .video__content .video__content-box .video__content-title {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
}
.video__screen .video__content .video__content-box .video__content-title h3 {
    font-size: 32px;
    font-weight: bold;
    color: #ed8154;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants {
    width: 100%;
    max-width: 1300px;
    height: auto;
    padding-top: 19px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant {
    width: calc(50% - 20px);
    max-width: 640px;
    height: 360px;
    margin: 10px;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant.participant--me {
    border: solid 3px #982727;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-image {
    width: 100%;
    height: 360px;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-image
    img {
    width: 100%;
    height: 360px;
    object-fit: cover;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-name {
    position: absolute;
    left: 20px;
    bottom: 20px;
    width: 150px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
    padding: 3px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-name
    h4 {
    font-size: 14px;
    color: #fff;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-more {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    right: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-role {
    position: absolute;
    width: 125px;
    height: 51px;
    border-radius: 8px;
    background-color: #ed8154;
    top: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video__screen
    .video__content
    .video__content-box
    .video__content-participants
    .video__content-participant
    .video__content-participant-role
    span {
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}
.video__screen .video__conversation {
    min-width: 384px;
    height: auto;
    background-color: #f2f2f2;
    height: 100%;
    min-height: 100vh;
    padding: 32px;
    position: sticky;
    top: 0px;
    right: 0px;
}
.video__screen .video__conversation .conversation__search {
    width: 100%;
    height: 56px;
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.video__screen .video__conversation .conversation__search input {
    width: 100%;
    height: 56px;
    outline: none;
    border: 0px;
    padding-left: 56px;
}
.video__screen .video__conversation .conversation__search img {
    position: absolute;
    top: 50%;
    left: 19px;
    transform: translateY(-50%);
}
.video__screen .video__conversation .conversation__title {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 21px;
}
.video__screen .video__conversation .conversation__title h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333333;
}
.video__screen .video__conversation .conversation__body {
    width: 100%;
    height: auto;
    padding-top: 20px;
    overflow-y: auto;
}
.video__screen .video__conversation .conversation__body .message {
    width: 100%;
    height: auto;
    padding-right: 80px;
    margin-bottom: 40px;
}
.video__screen .video__conversation .conversation__body .message.message--self {
    padding-left: 80px;
    padding-right: 0px;
}
.video__screen
    .video__conversation
    .conversation__body
    .message.message--self
    .message__content {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 10px;
    background-color: #ed8154;
}
.video__screen
    .video__conversation
    .conversation__body
    .message.message--self
    .message__content
    p {
    color: #fff;
}
.video__screen
    .video__conversation
    .conversation__body
    .message
    .message__content {
    width: 100%;
    height: auto;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    border-bottom-left-radius: 0px;
}
.video__screen
    .video__conversation
    .conversation__body
    .message
    .message__content
    p {
    font-size: 14px;
    line-height: 22px;
    color: #666;
    font-weight: 400;
}
.video__screen
    .video__conversation
    .conversation__body
    .message
    .message__about {
    width: 100%;
    height: auto;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.video__screen
    .video__conversation
    .conversation__body
    .message
    .message__about
    h5,
.video__screen
    .video__conversation
    .conversation__body
    .message
    .message__about
    span {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
}
.video__screen .video__conversation .conversation__send-message {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0px;
    bottom: 0px;
    padding: 32px;
}
.video__screen .video__conversation .conversation__send-message input {
    width: 100%;
    height: 56px;
    font-size: 14px;
    font-weight: 400;
    color: #666;
    border: 0px;
    border-radius: 10px;
    background-color: #fff;
    outline: none;
    padding: 0px 24px;
}
.video__screen .video__conversation .conversation__send-message img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 60px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
}
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.switch .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}
.switch input:checked + .slider {
    background-color: #ed8154;
}
.switch input:focus + .slider {
    box-shadow: 0 0 1px #ed8154;
}
.switch input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}
.switch .slider.round {
    border-radius: 20px;
}
.switch .slider.round:before {
    border-radius: 50%;
}

.login__page {
    width: 100%;
    height: auto;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("./assets/img/bg_login.svg");
    background-size: cover;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99;
}
.login__page .login__page-text {
    width: 600px;
    height: auto;
    min-height: 400px;
    padding: 40px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    margin: 0px 15px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login__page .login__page-text h4 {
    font-size: 20px;
    font-weight: 600;
    color: #ed8154;
}
.login__page .login__page-text p {
    font-size: 19px;
    padding-top: 20px;
    font-weight: 400;
    color: #4f4f4f;
    line-height: 28px;
}
.login__page .login__page-form {
    width: 500px;
    height: auto;
    min-height: 400px;
    padding: 40px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5);
    margin: 0px 15px;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login__page .login__page-form form {
    width: 100%;
    height: auto;
}
.login__page .login__page-form h4 {
    font-size: 20px;
    font-weight: 600;
    color: #ed8154;
    margin-bottom: 30px;
}
.login__page .login__page-form input {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 1px solid #bdbdbd;
    margin-bottom: 20px;
    padding-left: 10px;
}
.login__page .login__page-form button {
    width: 100%;
    height: 40px;
    border-radius: 8px;
    border: 0px;
    background-color: #ed8154;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}

.login__page .login__page-form ul {
    width: 100%;
    height: auto;
    border-top: 1px solid #bdbdbd;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.login__page .login__page-form ul li {
    display: inline-flex;
}
.login__page .login__page-form ul li a {
    font-size: 14px;
    font-weight: 400;
    color: #417ac7;
}
/*
APP ENDS HERE
*/
